import axios from "axios";
export const state = () => ({
    article_data: [],
    tab_id: 1,
    tab_highlight_style: {
        left: 0,
        width: 0
    },
    window_width: 0
})
export const mutations = {
    setArticleData(state, article_data){
        state.article_data = article_data;
    },
    updateTabId(state, id) {
        state.tab_id = id;
    },
    updateTabWidth(state, width){
        state.tab_highlight_style.width = width;
    },
    updateTabLeft(state, left) {
        state.tab_highlight_style.left = left;
    },
    updateWindowWidth(state, width) {
        state.window_width = width;
    }
}
export const actions = {
    async getArticleData({commit}, item){
        const API_url = 'https://styleme.pixnet.net/api/articles/categorylist/' + item.id;
        const data = await axios.get(API_url , 
                         { params: item.params, responseType: "json" })
                         .catch((e)=> {
                            console.log(e)
                         });
        if (data.status === 200) {
            let items = data.data.articles
            commit('setArticleData', items);
        } else {
            return Promise.reject(data);
        }
    },
}