
import axios from "axios";
import SearchResultComponent from './SearchResultComponent.vue';
import { mapMutations } from 'vuex';
import debounce from "lodash.debounce";
import FalconAd from "~/components/common/widget/FalconAd.vue";

export default {
    name: "search-bar",
    components: { SearchResultComponent, FalconAd },
    data() {
        return {
            input_value: '',
            hot_search: [
                {
                    name: '大試用時代',
                    link: 'https://trialsquad.pixnet.net/最新?utm_source=index&utm_campaign=search_open'
                }
            ],
            falcon_ad_container: 'falcon_5051',
        }
    },
    methods: {
        ...mapMutations('search', [
            'updateSearchHistory',
            'updateSearchActiveStatus',
            'updateSearchAutocomplete',
            'updateInputValueActiveStatus'
        ]),
        cleanRecent() {
            localStorage.removeItem("pix_search_history");
            this.updateSearchHistory([]);
        },
        closeSearch() {
            this.updateSearchActiveStatus(false);
            document.body.classList.remove('body-lock');
        },
        paFunction() {
            this.history_search.forEach((ele, index) => {
                let tag = '最近搜尋::' + ele
                let item_rank = 1 + ((index + 1) / 10000);
                window._piq.push(['trackEvent', 'rs2022::十大頻道::搜尋', 'imps::最近搜尋::hashtag_card::user_history', tag, item_rank]);
            })
            this.hot_search.forEach((ele, index) => {
                let tag = '全站熱搜標籤::' + ele
                let item_rank = 1 + ((index + 1) / 10000);
                window._piq.push(['trackEvent', 'rs2022::十大頻道::搜尋', 'imps::全站熱搜標籤::hashtag_card::curation_tag', tag, item_rank]);
            });
        },
        paInputValue(text) {
            let value = '全站搜尋::' + text
            _piq.push(['trackEvent', 'rs2022::十大頻道::搜尋', 'search::全站搜尋::button::user_action',value , '']);
        },
        inputValue(keyword) {
            if(keyword != '') {
                this.paInputValue(keyword);
                window.open(`https://curation.pixnet.net/${keyword}`, '_blank');
                this.addToLocalStorage(keyword);
                this.setSearchTextUrl(keyword);
                // this.input_value = '';
            }
        },
        addToLocalStorage(keyword) {
            let history = JSON.parse(localStorage.getItem("pix_search_history"));
            if (history === null) history = [];

            let searchObj =  {
                name: keyword,
                link: `https://curation.pixnet.net/${keyword}`
            }

            history.push(searchObj);
            localStorage.setItem("pix_search_history", JSON.stringify(history));
        },
        setSearchTextUrl(text) {
            let url = window.location.href;
            let inputUrl = new URL(url);
            inputUrl.searchParams.set('q', text);
            window.open(inputUrl.href, '_self');
        },
        cleanText() {
            this.input_value = '';
            this.updateSearchAutocomplete([]);
        },
        async getAutoCompleteFromApi() {
            if ( this.input_value != "") {
                const API_url = "https://api.pixnet.cc/tags/suggestions";
                const params = { q: this.input_value }
                const data = await axios.get(API_url, { 
                            params: params, responseType: "json" })
                            .catch((e)=> {
                                console.log(e)
                            });
                console.log('auto', data.data.data);   
                this.updateSearchAutocomplete(data.data.data)
            } else {
                this.updateSearchAutocomplete([])
            }
        },
        rebootPixAd() {
            if (window.PIXFalcon) {
                var pixAd;
                pixAd = new PIXFalcon();
                pixAd.placeAds(`#${this.falcon_ad_container}`);
            }
        },
    },
    computed: {
        history_search() {
            return this.$store.state.search.search_history;
        },
        search_active_status() {
            return this.$store.state.search.search_active;
        },
        search_autocomplete() {
            return this.$store.state.search.search_autocomplete;
        },
        input_value_active() {
            return this.$store.state.search.input_value_active;
        },
        safari_InnerHeight() {
            return this.$store.state.safariInnerHeight;
        }
    },
    created() {
        this.debouncedHandler = debounce(e => {
            this.getAutoCompleteFromApi();
            if(this.input_value != "" && this.input_value != null && this.input_value != undefined) {
                this.updateInputValueActiveStatus(true);
            } else {
                this.updateInputValueActiveStatus(false);
            }
        }, 300);
    },
    beforeUnmount() {
        this.debouncedHandler.cancel();
    },
    async mounted() {
        const params = { type: 1 }
        const api_url = "https://www.pixnet.net/mainpage/api/channel/getSiteHotTags";
        const data = await axios.get(api_url, { params: params, responseType: "json" })
            .catch((e) => {
                console.log(e)
            });
        if (data.status === 200 && !data.error) {
            data.data.data.tags.forEach(e => {
                this.hot_search.push(e)
            });
        } else {
            return Promise.reject(data);
        }
        this.rebootPixAd();
    }
}
