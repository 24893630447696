import axios from "axios";

export const state = () => ({
    random_list_data: [],
    random_list_title: "",
    random_list_link: "",
    list_model_id: ""
})

export const mutations = {
    setRandomArticleListData(state,random_list_data) {
        state.random_list_data = random_list_data;
    },
    setRandomArticleListTitle(state,random_list_title) {
        state.random_list_title = random_list_title;
    },
    setRandomArticleListLink(state, random_list_link) {
        state.random_list_link = random_list_link;
    },
    setArticleListModelId(state, list_model_id) {
        state.list_model_id = list_model_id;
    }
}

export const actions = {
    async getRandomArticleListData({commit}){
        const list = [
            { title:"美食", params: { channel_id: 1, page_size: 5, with_images: 1, with_author: 1, with_blog: 1 }, link: "https://www.pixnet.net/blog/articles/group/1" },
            { title:"旅遊", params: { channel_id: 2, page_size: 5, with_images: 1, with_author: 1, with_blog: 1 }, link: "https://www.pixnet.net/blog/articles/group/2"},
            { title:"3C", params: { channel_id: 3, page_size: 5, with_images: 1, with_author: 1, with_blog: 1 }, link: "https://www.pixnet.net/blog/articles/group/3" },
            { title:"親子", params: { channel_id: 4, page_size: 5, with_images: 1, with_author: 1, with_blog: 1 }, link: "https://www.pixnet.net/blog/articles/category/6" },
            { title:"影視", params: { channel_id: 5, page_size: 5, with_images: 1, with_author: 1, with_blog: 1 }, link: "https://www.pixnet.net/blog/articles/group/5" },
            { title:"美妝", params: { channel_id: 6, page_size: 5, with_images: 1, with_author: 1, with_blog: 1 }, link: "https://styleme.pixnet.net/home" },
            { title:"健康", params: { channel_id: 7, page_size: 5, with_images: 1, with_author: 1, with_blog: 1 }, link: "https://www.pixnet.net/blog/articles/group/7" },
            { title:"職涯", params: { channel_idid: 8, page_size: 5, with_images: 1, with_author: 1, with_blog: 1 }, link: "https://www.pixnet.net/blog/articles/category/9" },
            { title:"身心靈", params: { channel_id: 9, page_size: 5, with_images: 1, with_author: 1, with_blog: 1 }, link: "https://www.pixnet.net/blog/articles/group/9" },
            { title:"住家", params: { channel_id: 10, page_size: 5, with_images: 1, with_author: 1, with_blog: 1 }, link: "https://www.pixnet.net/blog/articles/group/10" },
            { title:"寵物", params: { channel_id: 11, page_size: 5, with_images: 1, with_author: 1, with_blog: 1 }, link: "https://www.pixnet.net/blog/articles/category/3" }
        ];
        const random = Math.floor(Math.random()*list.length)
        const API_url = 'https://www.pixnet.net/mainpage/api/channel/getSuggestArticle';
        const data = await axios.get(API_url , 
                         { params: list[random].params, responseType: "json" })
                         .catch((e)=> {
                            console.log(e)
                        });
        if (data.status === 200 && !data.error) {
            commit('setArticleListModelId', data.data.model_id)
            commit('setRandomArticleListTitle', list[random].title);
            commit('setRandomArticleListLink', list[random].link);
            commit('setRandomArticleListData', data.data.data.articles);
        } else {
            return Promise.reject(data);
        }
    }
}