
export default {
    name: 'search-result-component',
    props: {
        searchItem: Object,
        section: String,
        index: Number
    },
    methods: {
        goToPpage() {
            window.open(this.searchItem.link, '_blank');
        },
        paClickHandler() {
            this.goToPpage()
            let item_rank = 1 + ((this.index + 1)/10000);
            let str = this.section === '最近搜尋' ? 'hashtag_card::user_history' : 'inputbox::curation_tag';

            window._piq.push([
                'trackEvent',
                'rs2022::十大頻道::搜尋',
                `click::${this.section}::${str}`,
                `${this.section}::${this.searchItem.name}`,
                item_rank
            ]);
        }
    }
}
