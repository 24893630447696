
import { mapMutations } from 'vuex'; 
export default {
    name: "app-direct-icon",
    methods: {
        ...mapMutations('app_direct', [
            'setLightboxStatus',
        ]),
    }
}
