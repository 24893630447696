// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../static/img/public/qrcode_bg.png");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "@media (max-width: 1024px){.appDirectQr{display:none}}.appDirectQr>div{z-index:-1;opacity:0}.appDirectQr__lightbox{position:fixed;display:flex;justify-content:center;align-items:center;width:100%;height:100vh;background:rgba(0,0,0,0.5)}.appDirectQr__lightbox.active{z-index:70;opacity:1}.appDirectQr__lightbox .lightbox-background{position:relative;display:flex;justify-content:center;align-items:center;width:444px;height:478px;background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");border-radius:24px;background-repeat:no-repeat;background-size:cover}.appDirectQr__lightbox .lightbox-container{display:flex;flex-direction:column;justify-content:center;align-items:center}.appDirectQr__lightbox .lightbox-container .app-slogan{display:flex;flex-direction:column;justify-content:center;align-items:center;margin-bottom:16px}.appDirectQr__lightbox .lightbox-container .app-slogan .app-title{font-weight:600;font-size:30px;line-height:42px;color:#0a0a0a}.appDirectQr__lightbox .lightbox-container .app-slogan .app-title span{font-size:30px;color:#ff7200}.appDirectQr__lightbox .lightbox-container .app-slogan .app-sub-title{font-size:14px;line-height:20px;letter-spacing:.056px;color:#616161}.appDirectQr__lightbox .lightbox-container .app-qr-code{width:200px;height:200px;margin-bottom:32px}.appDirectQr__lightbox .lightbox-container .app-shop{display:flex;justify-content:center}.appDirectQr__lightbox .lightbox-container .app-shop img{height:40px;margin:0 4.5px}.app-close{position:absolute;bottom:-92px;left:calc(50% - 22px);display:flex;justify-content:center;align-items:center;width:44px;height:44px;background:#fff;border-radius:50%;cursor:pointer}\n", ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___;
