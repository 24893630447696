import axios from "axios";
export const state = () => ({
    is_has_profile_data: false,
    profile_data: {},
    tab_id: 1,
    tab_highlight_style: {
        left: 0,
        width: 0
    },
    fan_tab_id: 1,
    fan_tab_highlight_style: {
        left: 0,
        width: 0
    },
    window_width: 0,
    user_article: [],
    user_short_article: [],
    user_fans_count: 0,
    user_follow_count: 0,
    user_block_count: 0,
    user_article_count: 0,
    user_short_article_count: 0,
    user_short_article_cursor: '',
    user_relation_list: {
        fans: [],
        follow: [],
        block: []
    },
    pcard_fans_list_status: false,
    user_blog_category: {
        category: '',
        rank: 0
    },
    article_is_loading: true,
    block_check_box: false,
    block_display_name: '',
    unblock_check_box: false,
    unfollow_check_box: false,
    unfollow_data: {
        uniqid: '',
        name: ''
    },
    user_total_point: 0,
    user_point_intro_status: false,
    user_point_rank_detail: 0,
})

export const mutations = {
    setProfileStatus(state, data) {
        state.is_has_profile_data = data;
    },
    setProfileData(state, data) {
        state.profile_data = data;
    },
    setUserArticle(state, data) {
        state.user_article = data;
    },
    setUserMoreArticle(state, data) {
        state.user_article = state.user_article.concat(data);
    },
    setArticleLoadingStatus(state, status) {
        state.article_is_loading = status;
    },
    setUnfollowData(state, data) {
        state.unfollow_data.uniqid = data.uniqid;
        state.unfollow_data.name = data.name;
    },
    setBlockDisplayName(state, name) {
        state.block_display_name = name;
    },
    toggleBlockCheckBox(state) {
        state.block_check_box = !state.block_check_box;
    },
    toggleUnfollowCheckBox(state) {
        state.unfollow_check_box = !state.unfollow_check_box;
    },
    toggleUnblockCheckBox(state) {
        state.unblock_check_box = !state.unblock_check_box;
    },
    updateUserArticleCount(state, count) {
        state.user_article_count = count;
    },
    setUserShortArticle(state, data) {
        state.user_short_article = data;
    },
    setUserMoreShortArticle(state, data) {
        state.user_short_article = state.user_short_article.concat(data);
    },
    updateUserShortArticleCount(state, count) {
        state.user_short_article_count = count;
    },
    updateTabId(state, id) {
        state.tab_id = id;
    },
    updateTabWidth(state, width) {
        state.tab_highlight_style.width = width;
    },
    updateTabLeft(state, left) {
        state.tab_highlight_style.left = left;
    },
    updateWindowWidth(state, width) {
        state.window_width = width;
    },
    updateFanTabId(state, id) {
        state.fan_tab_id = id;
    },
    updateFanTabWidth(state, width) {
        state.fan_tab_highlight_style.width = width;
    },
    updateFanTabLeft(state, left) {
        state.fan_tab_highlight_style.left = left;
    },
    setUserFansList(state, data) {
        state.user_relation_list.fans = data;
    },
    setUserFollowList(state, data) {
        state.user_relation_list.follow = data;
    },
    setUserBlockList(state, data) {
        state.user_relation_list.block = data;
    },
    setUserBlockCount(state, count) {
        state.user_block_count = count;
    },
    setUserFansCount(state, count) {
        state.user_fans_count = count;
    },
    setUserFollowCount(state, count) {
        state.user_follow_count = count;
    },
    setMoreUserFansList(state, data) {
        state.user_relation_list.fans = state.user_relation_list.fans.concat(data);
    },
    setMoreUserFollowList(state, data) {
        state.user_relation_list.follow = state.user_relation_list.follow.concat(data);
    },
    setMoreUserBlockList(state, data) {
        state.user_relation_list.block = state.user_relation_list.block.concat(data);
    },
    togglePcardFansList(state) {
        state.pcard_fans_list_status = !state.pcard_fans_list_status;
    },
    setUserBlogCategory(state, data) {
        state.user_blog_category.category = data.category;
        state.user_blog_category.rank = data.rank;
    },
    updateShortArticleCursor(state, cursor) {
        state.user_short_article_cursor = cursor;
    },
    updateUserPoint(state, point) {
        state.user_total_point = point;
    },
    toggleUserPointIntro(state) {
        state.user_point_intro_status = !state.user_point_intro_status;
    },
    toggleUserPointRankDetail(state, rank) {
        state.user_point_rank_detail = rank ?? 0;
    },
}
export const actions = {
    async getProfileData({ commit }, item) {
        const API_url = 'https://www.pixnet.net/mainpage/api/channel/getMember';
        const params = item.pcard_id?.length == 18 ? {
            member_uniqid: item?.pcard_id,
            member_token: item?.member_token,
            ver: 2,
            refresh: item?.refresh
        } : {
            user_name: item?.pcard_id,
            member_token: item?.member_token,
            ver: 2,
            refresh: item?.refresh
        }
        const data = await axios.get(API_url,
            { params: params, responseType: "json" })
            .catch((e) => {
                console.log(e);
                commit('setProfileStatus', false);
            });
        if (data) {
            if (data.status === 200 && !data.error) {
                commit('setProfileStatus', true);
                commit('setProfileData', data.data.data);
                commit('updateUserShortArticleCount', Math.ceil(data.data.data.counter?.short_post_count / 10));
            } else {
                commit('setProfileStatus', false);
                return Promise.reject(data);
            }
        } else {
            commit('setProfileStatus', false);
        }
    },
    async getUserArticle({ commit }, item) {
        const API_url = 'https://www.pixnet.net/mainpage/api/channel/getMemberArticleByModel';
        const params = {
            member_token: item?.member_token,
            member_uniqid: item?.member_uniqid,
            page_size: 20,
            skip_spam: 1
        }
        const data = await axios.get(API_url,
            { params: params, responseType: "json" })
            .catch((e) => {
                console.log(e)
            });
        commit('setArticleLoadingStatus', true);
        if (data.status === 200 && !data.error) {
            commit('setUserArticle', data.data.data);
            commit('setArticleLoadingStatus', false);
        } else {
            commit('setArticleLoadingStatus', false);
            return Promise.reject(data);
        }
    },
    async getMoreUserArticle({ commit }, item) {
        const API_url = 'https://www.pixnet.net/mainpage/api/channel/getMemberArticleByModel';
        const params = {
            member_uniqid: item?.member_uniqid,
            member_token: item?.member_token,
            page: item?.page,
            page_size: 20,
            skip_spam: 1
        }
        const data = await axios.get(API_url,
            { params: params, responseType: "json" })
            .catch((e) => {
                console.log(e)
            });
        if (data.status === 200 && !data.error) {
            commit('setUserMoreArticle', data.data.data);
        } else {
            return Promise.reject(data);
        }
    },
    async getUserArticleCount({ commit }, user_name) {
        const API_url = 'https://www.pixnet.net/mainpage/api/getUserArticles';
        const params = {
            user_name: user_name,
        }
        const data = await axios.get(API_url,
            { params: params, responseType: "json" })
            .catch((e) => {
                console.log(e)
            });
        if (data.status === 200 && !data.error) {
            let tmpArticleCount = data.data.data.articles_count;
            tmpArticleCount = parseInt(tmpArticleCount) / 20;
            commit('updateUserArticleCount', Math.ceil(tmpArticleCount));
        } else {
            return Promise.reject(data);
        }
    },
    async getUserShortArticle({ commit }, user_data) {
        const API_url = 'https://www.pixnet.net/mainpage/api/channel/getMemberPersonalArticleByModel';
        const params = {
            member_uniqid: user_data?.member_uniqid,
            member_token: user_data?.member_token,
            page_size: 10,
            with_images: 1,
            with_content: 1,
            with_author: 1
        }
        const data = await axios.get(API_url,
            { params: params, responseType: "json" })
            .catch((e) => {
                console.log(e)
            });
        if (data.status === 200 && !data.error) {
            if (data.data.data) {
                commit('updateShortArticleCursor', data.data.data.articles[data.data.data.articles.length - 1].id);
                commit('setUserShortArticle', data.data.data.articles);
            }
        } else {
            return Promise.reject(data);
        }
    },
    async getMoreUserShortArticle({ commit }, item) {
        const API_url = 'https://www.pixnet.net/mainpage/api/channel/getMemberPersonalArticleByModel';
        const params = {
            member_uniqid: item.member_uniqid,
            page_size: 10,
            with_images: 1,
            with_content: 1,
            with_author: 1,
            cursor: item.cursor
        }
        const data = await axios.get(API_url,
            { params: params, responseType: "json" })
            .catch((e) => {
                console.log(e)
            });
        if (data.status === 200 && !data.error) {
            commit('updateShortArticleCursor', data.data.data.articles[data.data.data.articles.length - 1].id);
            commit('setUserMoreShortArticle', data.data.data.articles);
        } else {
            return Promise.reject(data);
        }
    },
    async getRelationData({ commit }, item) {
        const API_url = 'https://www.pixnet.net/mainpage/api/channel/getMemberRelationList';
        const params = {
            member_uniqid: item?.member_uniqid,
            member_token: item?.member_token,
            type: item?.type,
            page_size: 20
        }
        const data = await axios.get(API_url,
            { params: params, responseType: "json" })
            .catch((e) => {
                console.log(e)
            });
        if (data.status === 200 && !data.error) {
            if (item.type == 'fans') {
                commit('setUserFansList', data.data.data.list);
                commit('setUserFansCount', data.data.data.count)
            } else if (item.type == 'follow') {
                commit('setUserFollowList', data.data.data.list);
                commit('setUserFollowCount', data.data.data.count)
            } else if (item.type == 'block') {
                commit('setUserBlockList', data.data.data.list);
                commit('setUserBlockCount', data.data.data.count)
            }

        } else {
            return Promise.reject(data);
        }
    },
    async getRelationDataAndReset({ commit }, item) {
        commit('setUserFansList', []);
        commit('setUserFollowList', []);
        commit('setUserBlockList', []);
        const API_url = 'https://www.pixnet.net/mainpage/api/channel/getMemberRelationList';
        const params = {
            member_uniqid: item?.member_uniqid,
            member_token: item?.member_token,
            type: item?.type,
            page_size: 20
        }
        const data = await axios.get(API_url,
            { params: params, responseType: "json" })
            .catch((e) => {
                console.log(e)
            });
        if (data.status === 200 && !data.error) {
            if (item.type == 'fans') {
                commit('setUserFansList', data.data.data.list);
                commit('setUserFansCount', data.data.data.count)
            } else if (item.type == 'follow') {
                commit('setUserFollowList', data.data.data.list);
                commit('setUserFollowCount', data.data.data.count)
            } else if (item.type == 'block') {
                commit('setUserBlockList', data.data.data.list);
                commit('setUserBlockCount', data.data.data.count)
            }

        } else {
            return Promise.reject(data);
        }
    },
    async getMoreRelationData({ commit }, item) {
        const API_url = 'https://www.pixnet.net/mainpage/api/channel/getMemberRelationList';
        const params = {
            member_uniqid: item?.member_uniqid,
            member_token: item?.member_token,
            type: item?.type,
            page_size: 20,
            page: item?.page
        }
        const data = await axios.get(API_url,
            { params: params, responseType: "json" })
            .catch((e) => {
                console.log(e)
            });
        if (data.status === 200 && !data.error) {
            if (item.type == 'fans') {
                commit('setMoreUserFansList', data.data.data.list);
            } else if (item.type == 'follow') {
                commit('setMoreUserFollowList', data.data.data.list);
            } else if (item.type == 'block') {
                commit('setMoreUserBlockList', data.data.data.list);
            }

        } else {
            return Promise.reject(data);
        }
    },
    async getUserBlogCategory({ commit }, member_uniqid) {
        const API_url = 'https://www.pixnet.net/pcard/api/getMemberBlogRank';
        const params = {
            member_uniqid: member_uniqid,
        }
        const data = await axios.get(API_url,
            { params: params, responseType: "json" })
            .catch((e) => {
                console.log(e)
            });
        if (data.status === 200 && !data.error) {
            commit('setUserBlogCategory', data.data.data);
        } else {
            return Promise.reject(data);
        }
    },
    async getUserPoint({ commit }, member_uniqid) {
        commit('updateUserPoint', 0);
        if (!member_uniqid) return;
        const API_url = 'https://behavior.pixplug.in/api/trial/getUserPoint';
        const params = { member_uniqid };
        try {
            const res = await axios.get(API_url,
                { params: params, responseType: "json" })
                .catch((e) => {
                    console.log(e)
                    return Promise.reject(e);
                });
            if (res.status === 200 && res.data.success && 'total_point' in res.data.data) {
                commit('updateUserPoint', res.data.data.total_point);
            } else {
                return Promise.reject(res);
            }
        } catch (e) {
            console.log('2', e)
            return Promise.reject(e);
        }
    },
}