import axios from "axios";
export const state = () => ({
    online_data: {
        online_user: 0,
        keyword_search: 0
    },
    safariInnerHeight: 0
})

export const mutations = {
    setOnlineData(state, data) {
        state.online_data.online_user = Number(data.online_user_count);
        state.online_data.keyword_search = Number(data.keyword_search_count);
    },
    updateSafariInnerHeight(state) {
        state.safariInnerHeight = window.innerHeight / 100;
    }
}

export const actions = {
    async getOnlineData({commit}){
        const API_url = 'https://www.pixnet.net/mainpage/api/channel/getRealTimeData';
        const data = await axios.get(API_url , 
                         { responseType: "json" })
                         .catch((e)=> {
                            console.log(e)
                         });
        if (data.status === 200 && !data.error) {
            commit('setOnlineData', data.data.data);
        } else {
            return Promise.reject(data);
        }
    },
}