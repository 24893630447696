
import { mapMutations } from 'vuex'; 
export default {
    name: "privacy-tab",
    data() {
        return {
            showAnimation: false,
        }
    },
    methods: {
        ...mapMutations('privacy', [
            'updateTabId',
            'updateTabLeft',
            'updateTabWidth',
            'updateWindowWidth'
        ]),
        activeTab(id) {
            if (this.tab_id != id) {
                this.setTabHighLighter(id);
            } 
        },
        countTabLeft(){
            return this.$refs.privacy_tab.getBoundingClientRect().left;
        },
        initTabHighLighter(id){
            const width = this.$refs.privacy_tab.children[id - 1].clientWidth;
            const left = this.$refs.privacy_tab.children[id - 1].getBoundingClientRect().left;
            const tab_left = this.countTabLeft();
            this.updateTabLeft(left - tab_left);
            this.updateTabWidth(width);
        },
        resizeTabHighLighter(id) {
            setTimeout(()=>{
                if(this.$refs.privacy_tab.children[id - 1] != undefined) {
                    const width = this.$refs.privacy_tab.children[id - 1].clientWidth;
                    const left = this.$refs.privacy_tab.children[id - 1].getBoundingClientRect().left;
                    const tab_left = this.countTabLeft();
                    this.updateTabLeft(left - tab_left);
                    this.updateTabWidth(width); 
                }
            }, 100)
            
        },
        setTabHighLighter(id){
            const width = this.$refs.privacy_tab.children[id - 1].clientWidth;
            const left = this.$refs.privacy_tab.children[id - 1].getBoundingClientRect().left;
            const tab_left = this.countTabLeft();
            this.updateTabLeft(left - tab_left);
            this.updateTabWidth(width);
            this.updateTabId(id);
        },
        setShowAnimation(){
            setTimeout(()=>{
                this.showAnimation = true;
            }, 500)
        },
        setWindowWidth() {
            this.updateWindowWidth(window.innerWidth)
        },
        executeWhenResize(e) {
            this.setWindowWidth();
            this.resizeTabHighLighter(this.tab_id);
        },
        moveTabFromRoute() {
            let route_name = this.$route.name;
            if(route_name === "regulation") {
                this.activeTab(1)
            } else if (route_name === "privacy") {
                this.activeTab(2)
            } else if (route_name === "regulation-goods") {
                this.activeTab(3)
            }
        }
    },
    computed: {
        tab_id() {
            return this.$store.state.privacy.tab_id;
        },
        tab_left() {
            return this.$store.state.privacy.tab_highlight_style.left;
        },
        tab_width() {
            return this.$store.state.privacy.tab_highlight_style.width;
        },
        window_width() {
            return this.$store.state.privacy.window_width;
        },
    },
    mounted() {
        window.addEventListener("resize", this.executeWhenResize);
        this.initTabHighLighter(this.tab_id);
        this.setShowAnimation();
        this.setWindowWidth();
        this.countTabLeft();

        this.moveTabFromRoute()
    },
    destroyed() {
        window.removeEventListener('resize', this.executeWhenResize);
    },
}
