import axios from "axios";
export const state = () => ({
    login_status: false,
    member_data: {
        member_uniqid: "",
        member_name: "",
        member_display_name: "",
        member_thumb: "",
        member_link: "",
        member_followed: null,
        member_is_adult: null
    },
    pix_member_token: {
        member_token: "",
        panel_login_name: "",
        toolbar_close: 0
    }
})

export const mutations = {
    setSafariPixMemberToken(state, data) {
        state.pix_member_token.member_token = data;
    },
    setPixMemberToken(state, data){
        state.pix_member_token.member_token = data.member_token;
        state.pix_member_token.panel_login_name = data.panel_login_name;
        state.pix_member_token.toolbar_close = data.toolbar_close;
    },
    setMemberData(state, data) {
        state.member_data.member_uniqid = data.uniqid;
        state.member_data.member_name = data.user_name;
        state.member_data.member_display_name = data.displayname;
        state.member_data.member_thumb = data.avatar;
        state.member_data.member_link = data.link;
        state.member_data.member_followed = data.followed;
        state.member_data.member_is_adult = data.isAdult;
        state.login_status = true;
    },
    setLogoutData(state) {
        state.login_status = false;

        state.pix_member.member_token = null;
        state.pix_member.panel_login_name = null;
        state.pix_member.toolbar_close = 0;
        
        state.member_data.member_uniqid = "";
        state.member_data.member_name = "";
        state.member_data.member_display_name = "";
        state.member_data.member_thumb = "";
        state.member_data.member_link = "";
        state.member_data.member_followed = null;
    }
}
