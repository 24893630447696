import axios from "axios";

export const state = () => ({
    lightbox: false,
    app_redirect_detail: {}
})

export const mutations = {
    setLightboxStatus(state, status) {
        state.lightbox = status;
    },
    setAppRedirectDetail(state, data) {
        state.app_redirect_detail = data;
    },
}

export const actions = {
    async getAppRedirectDetail({commit}) {
        const API_url = "https://www.pixnet.net/mainpage/api/getPromos";
        try {
            const data = await axios.get(API_url);
            if (data.status === 200 && !data.error) {
                commit('setAppRedirectDetail', data.data.data.index);
            } else {
                return Promise.reject(data);
            }
        } catch(e) {
            console.log(e)
        }
    },
}