export const state = () => ({
    share_link: "http://www.pixnet.net/",
    share_title: "",
    open_share: false,
})

export const mutations = {
    setShareLink(state, share_link) {
        state.share_link = share_link;
    },
    setShareTitle(state, share_title) {
        state.share_title = share_title;
    },
    toggleShareOpen(state) {
        state.open_share = !state.open_share;
    }
}