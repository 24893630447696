import axios from "axios";

export const state = () => ({
    marqeen_data: []
})

export const mutations = {
    setMarqeenData(state, marqeen_data) {
        state.marqeen_data = marqeen_data;
    }
}

export const actions = {
    async getMarqeenData({commit}, params) {
        const API_url = "https://www.pixnet.net/mainpage/api/channel/getMarquees";
        const data = await axios.get(API_url, { 
                                    params: params, responseType: "json" })
                                .catch((e)=> {
                                    console.log(e)
                                });
        if (data.status === 200 && !data.error) {
            commit('setMarqeenData', data.data.data.articles);
        } else {
            return Promise.reject(data);
        }
        
    }
}