import axios from "axios";

export const state = () => ({
    browse_data: []
})

export const mutations = {
    setBrowseData(state, browse_data) {
        state.browse_data = browse_data;
    }
}

export const actions = {
    async getBrowseData({commit}) {
        const API_url = 'https://www.pixnet.net/mainpage/api/channel/cacheUserReadArticles';
        const data = await axios.get(API_url , 
                         { responseType: "json" })
                         .catch((e)=> {
                            console.log(e)
                         });
        if (data.status === 200 && !data.error) {
            commit('setBrowseData', data.data.data);
        } else {
            return Promise.reject(data);
        }
    }
}