export const actions = {
    paIndex({ commit }, index) {
        return (1.0000 + index * 0.0001).toFixed(4);
    },
    paEvent({ commit }, { device, action, label, value }) {
        _piq.push(['trackEvent', `rs2022::十大頻道::首頁/${device}`, action, label, value]);
    },
    /**
     * pa確認元素是否在畫面上的function
     * value 判斷 index需設置在element data-index
     * @param { element } 想確認畫面的element 
     * @param { device }  裝置，mobile/desktop
     * @param { action }  pa action
     * @param { label }  pa label
     */
    async paViewEvent({ dispatch }, { element, device, action, label }) {
        const options = {
            threshold: 1
        }
        let callback = async (entries) => {
            for (let entry of entries) {
                if (entry.isIntersecting) {
                    const link = entry.target.href;
                    const index = entry.target.dataset.index;
                    try {
                        const dataIndex = await dispatch('paIndex', index);
                        await dispatch('paEvent', {
                            device: device,
                            action: action,
                            label: `${label}::${link}`,
                            value: dataIndex
                        });
                    } catch (error) {
                        console.error('Error in paViewEvent:', error);
                    }
                }
            }
        }
        const observer = new IntersectionObserver(callback, options);
        observer.observe(element);
    },
}
