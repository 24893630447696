export const state = () => ({
    report_open: false,
    blog_article_id: "",
    blog_article_link: "",
    blog_article_member_id: "",
    blog_report_response: false,
    report_member_open: false
})

export const mutations = {
    updateArticleId(state, article_id){
        state.blog_article_id = article_id;
    },
    updateArticleLink(state, article_link){
        state.blog_article_link = article_link;
    },
    updateArticleMemberId(state, blog_article_member_id){
        state.blog_article_member_id = blog_article_member_id;
    },
    toggleReport(state){
        state.report_open = !state.report_open;  
    },
    toggleReportResponse(state){
        state.blog_report_response = !state.blog_report_response;
    },
    toggleReportMember(state){
        state.report_member_open = !state.report_member_open;  
    },
}