import axios from "axios";
export const state = () => ({
    short_article_all: [],
    cursor_id: 0,
    lazy_loading_status: false,
    to_delete_short_article_id: '',
    open_delete_status: false,
    open_post_block: false,
    post_mode: 'create',
    edit_content: {}
})
export const mutations = {
    setShortArticleData(state,  data) {
        state.short_article_all = data
    },
    setCursorId(state, id) {
        state.cursor_id = id
    },
    setMoreShortArticleData(state, data) {
        data.forEach((e) => {
            state.short_article_all.push(e)
        })
    },
    toogleLazyLoadingStatus(state) {
        state.lazy_loading_status = !state.lazy_loading_status;
    },
    toogleDeleteBoxStatus(state) {
        state.open_delete_status = !state.open_delete_status;
    },
    setToDeleteShortArticleId(state, id) {
        state.to_delete_short_article_id = id;
    },
    toggleOpenPostBlock(state) {
        state.open_post_block = !state.open_post_block;
    },
    setPostMode(state, mode) {
        state.post_mode = mode;
    },
    setEditContent(state, content) {
        state.edit_content = content;
    }
}
export const actions = {
    async getShortArticleData({commit}, params){
        const API_url = 'https://www.pixnet.net/mainpage/api/channel/getSuggestPersonalArticleByModel';
        const data = await axios.get(API_url , 
                         { params: params, responseType: "json" })
                         .catch((e)=> {
                            console.log(e)
                         });
        if (data.status === 200) {
            const article_data = data.data.data.articles;
            commit('setShortArticleData', article_data);
            commit('setCursorId', article_data[article_data.length -1].id);
        } else {
            return Promise.reject(data);
        }
    },
    async getMoreShortArticleData({commit}, params){
        const API_url = 'https://www.pixnet.net/mainpage/api/channel/getSuggestPersonalArticleByModel';
        const data = await axios.get(API_url , 
                         { params: params, responseType: "json" })
                         .catch((e)=> {
                            console.log(e)
                         });
        if (data.status === 200) {
            const atticle_data = data.data.data.articles;
            commit('setMoreShortArticleData', atticle_data);
            commit('setCursorId', atticle_data[atticle_data.length -1].id);
            commit('toogleLazyLoadingStatus');
        } else {
            return Promise.reject(data);
        }
    },
}