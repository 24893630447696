
import axios from "axios";
import SearchResultComponent from './SearchResultComponent.vue';
import { mapMutations } from 'vuex'; 
export default {
    name: "search-bar",
    components: { SearchResultComponent },
    data(){
        return {
            hot_search: [
                {
                    name: "大試用時代",
                    link: "https://trialsquad.pixnet.net/最新?utm_source=index&utm_campaign=search_open"
                }
            ]
        }
    },
    methods: {
        ...mapMutations('search', [
            'updateSearchHistory'
        ]),
        cleanRecent() {
            localStorage.removeItem("pix_search_history");
            this.updateSearchHistory([]);
        },
        paFunction() {
            this.history_search.forEach((ele, index) => {
                let tag = '最近搜尋::'+ ele
                let item_rank = 1 + ((index + 1)/10000);
                window._piq.push(['trackEvent', 'rs2022::十大頻道::搜尋', 'imps::最近搜尋::hashtag_card::user_history', tag, item_rank]);
            })
            this.hot_search.forEach((ele, index) => {
                let tag = '全站熱搜標籤::'+ ele
                let item_rank = 1 + ((index + 1)/10000);
                window._piq.push(['trackEvent', 'rs2022::十大頻道::搜尋', 'imps::全站熱搜標籤::hashtag_card::curation_tag', tag, item_rank]);
            });
        }
    },
    computed: {
        history_search(){
            return this.$store.state.search.search_history;
        },
        search_active_status() {
            return this.$store.state.search.search_active;
        },
        search_autocomplete() {
            return this.$store.state.search.search_autocomplete;
        },
        input_value_active() {
            return this.$store.state.search.input_value_active;
        }
    },
    async mounted() {
        const params = { type: 1 }
        const api_url = "https://www.pixnet.net/mainpage/api/channel/getSiteHotTags";
        const data = await axios.get(api_url ,{ params: params, responseType: "json" })
                                .catch((e)=> {
                                    console.log(e)
                                });
        if (data.status === 200 && !data.error) {
            data.data.data.tags.forEach(e => {
                this.hot_search.push(e)
            });
        } else {
            return Promise.reject(data);
        }
    }
}
