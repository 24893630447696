import axios from "axios";
export const state = () => ({
    video_data: [],
    cursor_id: 0,
    is_loading: false
})
export const mutations = {
    setVideoData(state, { data, isAppend }){
        if (isAppend) {
            state.video_data.push(...data);
        } else {
            state.video_data = data;
        }

        state.cursor_id = data[data.length - 1]?.id;
    },
    setLoadingStatus(state, boolean) {
        state.is_loading = boolean;
    }
}
export const actions = {
    // #106520 
    // 首頁影音河道取隨機(cursorId及showCursor無效) 影音牆則以網址id取資料
    async getVideos({ commit, state, dispatch }, {token = '', cursorId = state.cursor_id, showCursor = 0, random = 0, tag= '', isAppend = false } = {}) {
        try {
            const { data } = await axios.get(`https://www.pixnet.net/mainpage/api/channel/getSuggestPersonalArticleByModel`, { params: {
                page_size: 20,
                is_video: 1,
                member_token: token,
                cursor: cursorId,
                show_cursor: showCursor,
                random: random,
                has_tag: tag
            }});
            commit('setVideoData', { data: data.data.articles, isAppend});
        } catch (error) {
            console.error(error);
            // 如果影片id不存在 重取一次隨機
            if (error.response?.status === 503) {
                dispatch('getVideos', { token, random: 1 });
            }
        }
    },
}