export const state = () => ({
    view_data: "follow",
    app_direct: false
})
export const mutations = {
    setViewData(state, view_data) {
        state.view_data = view_data;
    },
    closeAppDirect(state) {
        state.app_direct = true;
    }
}