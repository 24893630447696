export const state = () => ({
    product_ranking_data: [],
    top_product_article_data: []
})

export const mutations = {
    setProductRankData(state, data) {
        state.product_ranking_data = data
    },
    setTopProductArticleData(state, data) {
        state.top_product_article_data = data.sort((a,b) => a.date.replace(/\//g,'') - b.date.replace(/\//g,'')).reverse();
    }
}