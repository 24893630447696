
import SeoTag from '../common/SeoContainer.vue';
import Panel from './notification/Panel.vue';
import TabContainer from '../common/tab/TabContainer.vue';
import SearchBar from './searchBar/SearchBar.vue';
import { mapMutations } from 'vuex';
import axios from "axios";
import AppDirectIcon from '../common/AppDirectIcon.vue';

export default {
    name: "header-component",
    head() {
        return {
            script: [
                {
                    src: "https://member.pixnet.cc/api/checklogin.php?js=1&unique=pix&timestamp=" + Date.now().toString().substring(0, Date.now().toString().length - 3) + "&type=3"
                }
            ],
        }
    },
    data(){
        return {
            input_value: "",
            show_search_container: false,
            open_panel_type: false,
            has_unread: false,
            header_white: false
        }
    },
    computed: {
        is_login() {
            return this.$store.state.login.login_status;
        },
        member_data() {
            return this.$store.state.login.member_data;
        },
        member_thumb() {
            return this.$store.state.login.member_data.member_thumb;
        },
        member_token() {
            return this.$store.state.login.pix_member_token.member_token;
        },
        search_active_status() {
            return this.$store.state.search.search_active;
        },
        window_width() {
            return window.innerWidth;
        },
        tip() {
            return this.$store.state.app_direct.tip;
        },
        dark_mode() {
            if(this.$route.name === "Channel-Movie" || this.$route.name === "Channel-Adult") {
                return true;
            } else {
                return false;
            }
        },
        router_name() {
            return this.$route.name;
        },
    },
    components: { SeoTag, Panel, TabContainer, SearchBar, AppDirectIcon },
    methods: {
        ...mapMutations('search', [
            'updateSearchHistory',
            'updateSearchActiveStatus',
        ]),
        ...mapMutations('login', [
            'setPixMemberToken',
            'setSafariPixMemberToken',
            'setLogoutData',
            'setMemberData'
        ]),
        ...mapMutations('adult', [
            'changeIs18UpStatus'
        ]),
        openPanel() {
            document.body.classList.add('body-lock');
            this.open_panel_type = true;
        },
        closePanel() {
            document.body.classList.remove('body-lock');
            this.open_panel_type = false;
        },
        getSearchHistory() {
            let history = JSON.parse(localStorage.getItem("pix_search_history")) !== null ? JSON.parse(localStorage.getItem("pix_search_history")) : [];
            this.updateSearchHistory(history);
        },
        goLogin(){
            const url = window.location.host;
            location.href = "https://member.pixnet.cc/login/openid?openid=https://member.pixnet.cc/login&easy_login=1&done=https%3A%2F%2F"+ url +"%3Fversions%3D2023";
        },
        goLogout() {
            location.href = `https://member.pixnet.cc/logout?done=https%3A%2F%2F${window.location.host}${window.location.pathname}%3Fversions%3D2023`;
        },
        getMemberToken(data){
            if(data != {} && data != undefined && data.member_token != null) {
                this.setPixMemberToken(data);
            } else {
                this.setLogoutData();
            }  
        },
        hasUnread(val) {
            this.has_unread = val;
        },
        async getMemberLoginData(token){
            if (token != "") {
                const API_url = "https://www.pixnet.net/mainpage/api/channel/getMember";
                const params = { member_token: token }
                try {
                    const data = await axios.get(API_url, { 
                                params: params, responseType: "json" });
                    this.setMemberData(data.data.data);
                } catch (e) {
                    console.log(e);
                }
            } 
        },
        getCookie(cookie_name) {
            let name = cookie_name + "=";
            let decodedCookie = decodeURIComponent(document.cookie);
            let ca = decodedCookie.split(';');
            for(let i = 0; i <ca.length; i++) {
                let c = ca[i];
                while (c.charAt(0) == ' ') {
                    c = c.substring(1);
                }
                if (c.indexOf(name) == 0) {
                    let regex_str = /.*\|(.*)/;
                    let str = c.substring(name.length, c.length);
                    let group_str = regex_str.exec(str);
                    return JSON.parse(group_str[1]).data.safari_token;
                }
            }
            return "";
        },
        dynamicGetLoginToken() {
            const safari_token = this.getCookie("PIXCCSESSION");
            if (safari_token != undefined && safari_token != "" && safari_token != null) {
                this.setSafariPixMemberToken(safari_token);
                this.getMemberLoginData(this.member_token);
                this.checkLoginAndIs18up();
            }
            if (window.pix.member_token) {
                this.getMemberToken(window.pix);
                this.getMemberLoginData(this.member_token);
                this.checkLoginAndIs18up();
            }
        },
        setTabHighLighter(id){
            const width = this.$refs.tab_ele.$refs.category[id - 1].clientWidth;
            const left = this.$refs.tab_ele.$refs.category[id - 1].getBoundingClientRect().left;
            this.updateTabLeft(left);
            this.updateTabWidth(width);
        },
        checkLoginAndIs18up() {
            if(this.$cookies.get(this.member_data.member_uniqid + 'is_adult') != undefined && this.$cookies.get(this.member_data.member_uniqid + 'is_adult') == 'true') {
                this.changeIs18UpStatus();
            }
        },
        clickSearch() {
            this.updateSearchActiveStatus(true);
            document.body.classList.add('body-lock');
            this.paEvent('click::全站搜尋::inputbox::user_action');
        },
        clickNearby() {
            navigator.permissions.query({ name: 'geolocation' })
                .then(result => {
                    if (result.state === 'granted') {
                        navigator.geolocation.getCurrentPosition(
                                position => this.goToNearby(position),
                                error => this.handleGeoError(error))
                    } else {
                        this.handleGeoError('error')
                    }
                },
                error => this.handleGeoError(error));
        },
        goToNearby(position) {
            if (position) {
                let lat = position.coords.latitude;
                let lon = position.coords.longitude;
                let link = `https://map.events.pixnet.net/out/app.html?c=${lat},${lon}`;
                this.paEvent('click::附近頁籤::button::user_action', `附近頁籤::${link}`);
                window.location.href = link;
            } else {
                this.handleGeoError('error');
            }
        },
        handleGeoError(error) {
            console.log(error);
            this.paEvent('click::附近頁籤::button::user_action', `附近頁籤::https://map.events.pixnet.net/out/app.html?c=25.033976,121.5645389`);
            window.location.href = `https://map.events.pixnet.net/out/app.html?c=25.033976,121.5645389`;
        },
        /** kv看不見時，header要變成白色 */
        headerScrollChangeColor() {
            const v3Tv = document.querySelector('.v3__tv');
            const options = {
                threshold: 0,
                rootMargin: "-52px"
            }
            let callback = (entries) => {
                entries.forEach(entry => this.header_white = !entry.isIntersecting);
            };

            const observer = new IntersectionObserver(callback, options);
            if (v3Tv) observer.observe(v3Tv);            
        },
        paEvent(action, label) {
            _piq.push(['trackEvent', 'rs2022::十大頻道::首頁/mobile', action, label]);
        },
    },
    mounted() {
        this.dynamicGetLoginToken();
        this.getSearchHistory();
        this.headerScrollChangeColor();
    },
}
