
export default {
    layout:'error-page',
    mounted() {
        this.redirectToPage('/404');
    },
    methods: {
        redirectToPage(path) {
            this.$router.push(path);
        }
    }
}
