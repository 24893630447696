
export default {
    name: "falcon-ad",
    props: {
        adSlot: Number,
        ratio: Number,
        radius: {
            type: Boolean,
            default: true
        },
        height: Number,
    },
    data() {
        return {
            adElement: null,
            adHeight: null
        }
    },
    watch: {
        adElement: function () {
            if(this.ratio && this.ratio !== 100) {
                this.adHeight = `${this.adElement.clientWidth * this.ratio}px`;
            } else if (this.height) {
                this.adHeight = `${this.height}px`;
            } else {
                this.adHeight = 'auto';
            }
        }
    },
    mounted() {
        this.adElement = this.$refs.adElement;
    }
}
