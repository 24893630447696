import axios from "axios";

export const state = () => ({
    article_data: [],
    article_collection: [],
    list_num: 0,
    channel_article_list: [
        {channel: "美食", data: []},
        {channel: "影視", data: []},
        {channel: "旅遊", data: []},
        {channel: "親子", data: []},
        {channel: "寵物", data: []},
        {channel: "3C", data: []},
        {channel: "身心靈", data: []},
        {channel: "美妝", data: []},
        {channel: "健康", data: []},
        {channel: "職涯", data: []},
        {channel: "住家", data: []},
        {channel: "私康", data: []}
    ],
    model_id_index: "",
    followed_blogger_article: [],
    day_hot_article: [],
    day_hot_article_full: [],
    personal_river: [],
    lazy_loading_status: false,
})

export const mutations = {
    setArticleData(state, article_data){
        state.article_collection = article_data.slice(5, 10);
        state.article_data = article_data;
    },
    setMoreArticleData(state, article_data){
        article_data.forEach((e) => {
            state.article_data.push(e)
        })
    },
    setChannelArticleData(state, article_data) {
        state.channel_article_list[article_data.id - 2].data = article_data.data;  
    },
    setMoreChannelArticleData(state, article_data) {
        article_data.data.forEach(e => {
            state.channel_article_list[article_data.id - 2].data.push(e)
        });
    },
    setModelIdIndex(state, model_id) {
        state.model_id_index = model_id;
    },
    setFollowedBloggerArticleDataByMemberToken(state, article_data) {
        state.followed_blogger_article = article_data;
    },
    setMoreFollowedBloggerArticleDataByMemberToken(state, article_data) {
        article_data.forEach((e) => {
            state.followed_blogger_article.push(e)
        })
    },
    setDayHotArticle(state, article_data) {
        state.day_hot_article = article_data;
    },
    setDayHotArticleFull(state, article_data) {
        state.day_hot_article_full = article_data;
    },
    toogleLazyLoadingStatus(state) {
        state.lazy_loading_status = !state.lazy_loading_status;
    },
    setPersonalRiver(state, river_data) {
        state.personal_river = river_data;
    },
    setMorePersonalRiver(state, river_data) {
        state.personal_river = state.personal_river.concat(river_data);
    }
}

export const actions = {
    async getArticleData({commit}, params){
        const API_url = 'https://www.pixnet.net/mainpage/api/channel/getSuggestArticle2';
        const data = await axios.get(API_url , 
                         { params: params, responseType: "json" })
                         .catch((e)=> {
                            console.log(e)
                         });
        if (data.status === 200) {
            commit('setModelIdIndex', data.data.model_id);
            commit('setArticleData', data.data.data.articles);
        } else {
            return Promise.reject(data);
        }
    },
    async getMoreArticleData({commit}, params){
        const API_url = 'https://www.pixnet.net/mainpage/api/channel/getSuggestArticle2';
        const data = await axios.get(API_url , 
                         { params: params, responseType: "json" })
                         .catch((e)=> {
                            console.log(e)
                         });           
        if (data.status === 200) {
            commit('toogleLazyLoadingStatus');   
            commit('setMoreArticleData', data.data.data.articles);
        } else {
            commit('toogleLazyLoadingStatus'); 
            return Promise.reject(data);
        }
    },
    async getChannelArticleData({commit}, info){
        const API_url = 'https://www.pixnet.net/mainpage/api/channel/getArticles';
        const data = await axios.get(API_url , 
                         { params: info.params, responseType: "json" })
                         .catch((e)=> {
                            console.log(e)
                         });
        let article_data = {
            id: info.tab_id,
            data: data.data.data.articles
        }
        if (data.status === 200 && !data.error) {
            commit('setChannelArticleData', article_data); 
        } else {
            return Promise.reject(data);
        }
    },
    async getFollowedBloggerArticleDataByMemberToken({commit}, token){
        const API_url = 'https://www.pixnet.net/mainpage/api/channel/getPersonalRiver';
        const data = await axios.get(API_url , 
            { params: token, responseType: "json" })
            .catch((e)=> {
                console.log(e)
            });
        if (data.status === 200 && !data.error) {
            commit('setFollowedBloggerArticleDataByMemberToken', data.data.data); 
        } else {
            return Promise.reject(data);
        }
    },
    async getMoreFollowedBloggerArticleDataByMemberToken({commit}, params){
        const API_url = 'https://www.pixnet.net/mainpage/api/channel/getPersonalRiver';
        const data = await axios.get(API_url , 
            { params: params, responseType: "json" })
            .catch((e)=> {
                console.log(e)
            });
        if (data.status === 200 && !data.error) {
            commit('setMoreFollowedBloggerArticleDataByMemberToken', data.data.data); 
        } else {
            return Promise.reject(data);
        }
    },
    async getDayHotArticle({commit}, params) {
        const API_url = "https://www.pixnet.net/mainpage/api/blog/articles";
        const data = await axios.get(API_url , 
            { params: params, responseType: "json" }).catch((e)=> {
                console.log(e);
            });

        if (data.status === 200 && !data.error) {
            let items = data.data.data.articles;
            commit('setDayHotArticle', items.sort((a,b) => a.date.replace(/\//g,'') - b.date.replace(/\//g,'')).reverse().slice(0, 6)); 
            commit('setDayHotArticleFull', items.sort((a,b) => a.date.replace(/\//g,'') - b.date.replace(/\//g,'')).reverse()); 
        } else {
            return Promise.reject(data);
        }
    },
    async getDayHotArticleBeauty({commit}, params) {
        const API_url = "https://www.pixnet.net/mainpage/api/blog/articles";
        const data = await axios.get(API_url , 
            { params: params, responseType: "json" }).catch((e)=> {
                console.log(e);
            });
            
        if (data.status === 200 && !data.error) {
            let items = data.data.data.articles;
            commit('setDayHotArticle', items.slice(0, 6)); 
            commit('setDayHotArticleFull', items); 
        } else {
            return Promise.reject(data);
        }
    },
    async getArticleByPersonalRiverByModel({commit}, params) {
        const API_url = "https://www.pixnet.net/mainpage/api/channel/getPersonalRiverByModel";
        const data = await axios.get(API_url , 
            { params: params, responseType: "json" }).catch((e)=> {
                console.log(e);
            });
            
        if (data.status === 200 && !data.error) {
            let items = data.data.data;
            commit('setPersonalRiver', items)
        } else {
            return Promise.reject(data);
        }
    },
    async getMoreArticleByPersonalRiverByModel({commit}, params) {
        const API_url = "https://www.pixnet.net/mainpage/api/channel/getPersonalRiverByModel";
        const data = await axios.get(API_url , 
            { params: params, responseType: "json" }).catch((e)=> {
                console.log(e);
            });
            
        if (data.status === 200 && !data.error) {
            let items = data.data.data;
            commit('setMorePersonalRiver', items)
        } else {
            return Promise.reject(data);
        }
    }
}