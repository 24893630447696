
import axios from "axios";
export default {
    name: "article-card",
    props: {
        item: Object,
        mode: String
    },
    methods: {
        closeReview() {
            this.$parent.cleanPreviewUrl();
        }
    }
}
