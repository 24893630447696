import axios from "axios";
export const state = () => ({
    advertise_list: [
    ],
    model_id: '',
    advertise_list_desktop: []
})

export const mutations = {
    setAdvertiseList(state, list) {
        state.advertise_list = [];
        state.advertise_list = list;
        state.advertise_list_desktop = list.slice(0, 4);
    }
}

export const actions = {
    async getAdvertiseList({commit}) {
        const params = {
            rand: 1,
            hide_app: 1,
            type: "mainpage"
        }
        const API_url = 'https://topic.events.pixnet.net/api/getEventsInfo';
        const data = await axios.get(API_url , 
            { params: params, responseType: "json" })
            .catch((e)=> {
                console.log(e)
            });
        if (data.status === 200 && !data.error) {
            commit('setAdvertiseList', data.data.data); 
        } else {
            return Promise.reject(data);
        }
    }
}