
import { mapMutations, mapActions } from 'vuex';
import PostAndEditShortArticleModule from '../../child/post/PostAndEditShortArticleModule.vue';
import ToastContainer from '../../child/lightbox/ToastContainer.vue';
export default {
    name: 'post-button',
    components: {
        PostAndEditShortArticleModule,
        ToastContainer
    },
    data() {
        return {
            isPanelOpen: false,
            topBtnShow: false,
            device: 'desktop'
        }
    },
    computed: {
        isLogin() {
            return this.$store.state.login.login_status;
        },
        openPostBlock() {
            return this.$store.state.short_article.open_post_block;
        },
    },
    methods: {
        ...mapMutations('short_article', [
            'toggleOpenPostBlock',
            'setPostMode'
        ]),
        ...mapActions("pa", [
            "paEvent",
        ]),
        createNewShortArticle() {
            this.toggleOpenPostBlock();
            this.setPostMode('create');
        },
        togglePanel() {
            this.isPanelOpen = !this.isPanelOpen;
            // PA 
            if (this.isPanelOpen) {
                const payload = {
                    device: this.device,
                    action: 'click::發文按鈕::button::user_action',
                }
                this.paEvent(payload);
            }
        },
        scrollTop() {
            window.scrollTo({
                top: 0,
                left: 0,
                behavior: "smooth",
            });
        },
        createArticle() {
            if (this.isLogin) {
                location.href = "https://panel.pixnet.cc/#/create-article";
            } else {
                location.href = "https://member.pixnet.cc/login/openid?openid=https://member.pixnet.cc/login&easy_login=1&done=https%3A%2F%2Fpanel.pixnet.cc%2F%23%2Fcreate-article";
            }
            // PA
            const payload = {
                device: this.device,
                action: 'click::創作文章按鈕::button::user_action',
                label: '文章::發布'
            }
            this.paEvent(payload);
        },
        createShortPost() {
            if (this.isLogin) {
                this.toggleOpenPostBlock();
                this.setPostMode('create');
            } else {
                location.href = `https://member.pixnet.cc/login/openid?openid=https://member.pixnet.cc/login&easy_login=1&done=https%3A%2F%2F${window.location.host}${window.location.pathname}%3Fversions%3D2023`;
            }

            // PA
            const payload = {
                device: this.device,
                action: 'click::建立貼文按鈕::button::user_action',
                label: '短貼文::發布'
            }
            this.paEvent(payload);
        },
        openToast(){
            this.$refs.toast_box.openToastElement();
        },
        chackScroll() {
            window.addEventListener('scroll', () => {
                this.isPanelOpen = false;
                let scrollHeight = this.device === 'desktop' ? 1500 : 1000;
                this.topBtnShow = window.scrollY > scrollHeight;
            });
        },
        paCheckViewEvent() {
            const payload = {
                device: this.device,
                action: 'imps::發文按鈕::button::user_action',
            }
            this.paEvent(payload);
        },
    },
    mounted() {
        this.device = window.innerWidth > 768 ? 'mobile' : 'desktop';
        this.paCheckViewEvent();
        this.chackScroll();
    }
}
