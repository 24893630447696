import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _457bd578 = () => interopDefault(import('../pages/Channel/index.vue' /* webpackChunkName: "pages/Channel/index" */))
const _be495380 = () => interopDefault(import('../pages/mobile.vue' /* webpackChunkName: "pages/mobile" */))
const _9c073b96 = () => interopDefault(import('../pages/Pcard/index.vue' /* webpackChunkName: "pages/Pcard/index" */))
const _251a1eda = () => interopDefault(import('../pages/privacy.vue' /* webpackChunkName: "pages/privacy" */))
const _11557a48 = () => interopDefault(import('../pages/regulation.vue' /* webpackChunkName: "pages/regulation" */))
const _450dd511 = () => interopDefault(import('../pages/regulation-goods.vue' /* webpackChunkName: "pages/regulation-goods" */))
const _5d5042fd = () => interopDefault(import('../pages/v3mobile.vue' /* webpackChunkName: "pages/v3mobile" */))
const _34aa1520 = () => interopDefault(import('../pages/Video/index.vue' /* webpackChunkName: "pages/Video/index" */))
const _c9d67846 = () => interopDefault(import('../pages/Channel/3c/index.vue' /* webpackChunkName: "pages/Channel/3c/index" */))
const _484a10c3 = () => interopDefault(import('../pages/Channel/Adult/index.vue' /* webpackChunkName: "pages/Channel/Adult/index" */))
const _433a31e9 = () => interopDefault(import('../pages/Channel/Beauty/index.vue' /* webpackChunkName: "pages/Channel/Beauty/index" */))
const _4681c6ce = () => interopDefault(import('../pages/Channel/Body-Mind-Spirit/index.vue' /* webpackChunkName: "pages/Channel/Body-Mind-Spirit/index" */))
const _4f3ece6b = () => interopDefault(import('../pages/Channel/Career/index.vue' /* webpackChunkName: "pages/Channel/Career/index" */))
const _e6700a5e = () => interopDefault(import('../pages/Channel/Family/index.vue' /* webpackChunkName: "pages/Channel/Family/index" */))
const _6ef1306a = () => interopDefault(import('../pages/Channel/Food/index.vue' /* webpackChunkName: "pages/Channel/Food/index" */))
const _46da4bc9 = () => interopDefault(import('../pages/Channel/Health/index.vue' /* webpackChunkName: "pages/Channel/Health/index" */))
const _11c4630c = () => interopDefault(import('../pages/Channel/Home/index.vue' /* webpackChunkName: "pages/Channel/Home/index" */))
const _512252b9 = () => interopDefault(import('../pages/Channel/Movie/index.vue' /* webpackChunkName: "pages/Channel/Movie/index" */))
const _7cd4a968 = () => interopDefault(import('../pages/Channel/Pet/index.vue' /* webpackChunkName: "pages/Channel/Pet/index" */))
const _c9c519f2 = () => interopDefault(import('../pages/Channel/Travel/index.vue' /* webpackChunkName: "pages/Channel/Travel/index" */))
const _32bf5aa0 = () => interopDefault(import('../pages/Pcard/_id/index.vue' /* webpackChunkName: "pages/Pcard/_id/index" */))
const _816f9868 = () => interopDefault(import('../pages/Video/_videoId/index.vue' /* webpackChunkName: "pages/Video/_videoId/index" */))
const _7f7950f8 = () => interopDefault(import('../pages/Pcard/_id/*.vue' /* webpackChunkName: "pages/Pcard/_id/*" */))
const _025c55a4 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _4bb46dfc = () => interopDefault(import('../pages/*.vue' /* webpackChunkName: "pages/*" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/Channel",
    component: _457bd578,
    name: "Channel"
  }, {
    path: "/mobile",
    component: _be495380,
    name: "mobile"
  }, {
    path: "/Pcard",
    component: _9c073b96,
    name: "Pcard"
  }, {
    path: "/privacy",
    component: _251a1eda,
    name: "privacy"
  }, {
    path: "/regulation",
    component: _11557a48,
    name: "regulation"
  }, {
    path: "/regulation-goods",
    component: _450dd511,
    name: "regulation-goods"
  }, {
    path: "/v3mobile",
    component: _5d5042fd,
    name: "v3mobile"
  }, {
    path: "/Video",
    component: _34aa1520,
    name: "Video"
  }, {
    path: "/Channel/3c",
    component: _c9d67846,
    name: "Channel-3c"
  }, {
    path: "/Channel/Adult",
    component: _484a10c3,
    name: "Channel-Adult"
  }, {
    path: "/Channel/Beauty",
    component: _433a31e9,
    name: "Channel-Beauty"
  }, {
    path: "/Channel/Body-Mind-Spirit",
    component: _4681c6ce,
    name: "Channel-Body-Mind-Spirit"
  }, {
    path: "/Channel/Career",
    component: _4f3ece6b,
    name: "Channel-Career"
  }, {
    path: "/Channel/Family",
    component: _e6700a5e,
    name: "Channel-Family"
  }, {
    path: "/Channel/Food",
    component: _6ef1306a,
    name: "Channel-Food"
  }, {
    path: "/Channel/Health",
    component: _46da4bc9,
    name: "Channel-Health"
  }, {
    path: "/Channel/Home",
    component: _11c4630c,
    name: "Channel-Home"
  }, {
    path: "/Channel/Movie",
    component: _512252b9,
    name: "Channel-Movie"
  }, {
    path: "/Channel/Pet",
    component: _7cd4a968,
    name: "Channel-Pet"
  }, {
    path: "/Channel/Travel",
    component: _c9c519f2,
    name: "Channel-Travel"
  }, {
    path: "/Pcard/:id",
    component: _32bf5aa0,
    name: "Pcard-id"
  }, {
    path: "/Video/:videoId",
    component: _816f9868,
    name: "Video-videoId"
  }, {
    path: "/Pcard/:id/*",
    component: _7f7950f8,
    name: "Pcard-id-*"
  }, {
    path: "/",
    component: _025c55a4,
    name: "index"
  }, {
    path: "/*",
    component: _4bb46dfc,
    name: "*"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
