export const state = () => ({
    search_history: [],
    search_active: false,
    search_autocomplete: [],
    input_value_active: false
})

export const mutations = {
    updateSearchHistory(state, history){
        state.search_history = history;
    },
    updateSearchActiveStatus(state, status) {
        state.search_active = status;
    },
    updateSearchAutocomplete(state, result) {
        state.search_autocomplete = result;
    },
    updateInputValueActiveStatus(state, status) {
        state.input_value_active = status;
    }
}