
import { mapMutations } from 'vuex'; 
export default {
    name: "app-direct-qr",
    computed: {
        lightbox() {
            return this.$store.state.app_direct.lightbox;
        },
    },
    methods: {
        ...mapMutations('app_direct', [
            'setLightboxStatus',
        ])
    }
}
