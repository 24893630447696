import axios from "axios";

export const state = () => ({
    blogger_data: [],
    blogger_ranking_data: [
    ],
    blogger_top_rank: {
        cache_key: '',
        uniqid: '',
        user_name: '',
        displayname: '',
        cover: '',
        avatar: '',
        link: '',
        profile: {
            self_intro: '',
            fb_page: '',
            instagram: '',
            youtube: '',
            hourmaster: '',
            email: '',
            pss_headframes: []
        },
        counter: {
            fans_count: '',
            follow_count: 0,
            post_count: '',
            short_post_count: 0,
            hot: 0
        },
        blog: {
            name: '',
            link: '',
            introduction: '',
            hot: ''
        },
        cache: true,
        articles: {
            user: '',
            articles_count: '',
            page: 1,
            page_size: 1,
            articles: [
                {
                    cache: true,
                    cache_key: "",
                    comment_count: 0,
                    date: "",
                    hot: "",
                    id: "",
                    image: "",
                    link: "",
                    shared_count: 0,
                    time: "",
                    title: "",
                    type: "article" 
                }
            ]
        }
    },
    blogger_login_data: [],
    blogger_top_rank_model_id: '',
    blogger_login_follow_model_id: '',
    blogger_follow_model_id: ''
})

export const mutations = {
    setBloggerData(state, blogger_data) {
        state.blogger_data = blogger_data;
    },
    setBloggerRankingData(state, blogger_ranking_data) {
        state.blogger_ranking_data = blogger_ranking_data;
    },
    setBloggerTopRank(state, blogger_top_rank) {
        state.blogger_top_rank = blogger_top_rank;
    },
    setBloggerLoginData(state, blogger_login_data) {
        state.blogger_login_data = blogger_login_data;
    },
    setBloggerModelId(state, model_id) {
        state.blogger_top_rank_model_id = model_id
    },
    setBloggerLoginFollowModelId(state, id) {
        state.blogger_login_follow_model_id = id
    },
    setBloggerFollowModelId(state, id) {
        state.blogger_follow_model_id = id
    }
}

export const actions = {
    async getBloggerTopData({commit}, params){
        const API_url = 'https://www.pixnet.net/mainpage/api/channel/getTopBlogger';
        const data = await axios.get(API_url , 
                         { params: params, responseType: "json" })
                         .catch((e)=> {
                            console.log(e)
                         });
        if (data.status === 200 && !data.error) {
            commit('setBloggerFollowModelId', data.data.model_id);
            commit('setBloggerData', data.data.data);
        } else {
            return Promise.reject(data);
        }
    },
    async getBloggerNewData({commit}, params){
        const API_url = 'https://www.pixnet.net/mainpage/api/channel/getStarBlogger';
        const data = await axios.get(API_url , 
                         { params: params, responseType: "json" });
        if (data.status === 200 && !data.error) {
            commit('setBloggerData', data.data.data);
        } else {
            return Promise.reject(data);
        }
    },
    async getBloggerTopRankingData({commit}, params){
        const API_url = 'https://www.pixnet.net/mainpage/api/channel/getTopBlogger';
        /* 文件： http://showdoc.cmh.com.tw:4999/web/#/8/99 */
        const data = await axios.get(API_url , 
                         { params: params, responseType: "json" })
                         .catch((e)=> {
                            console.log(e)
                         });
        if (data.status === 200 && !data.error) {
            commit('setBloggerModelId', data.data.model_id);
            commit('setBloggerTopRank', data.data.data[0]); 
            commit('setBloggerRankingData', data.data.data);
        } else {
            return Promise.reject(data);
        }
    },
    async getBloggerNewRankingData({commit}, params){
        const API_url = 'https://www.pixnet.net/mainpage/api/channel/getStarBlogger';
        const data = await axios.get(API_url , 
                    { params: params, responseType: "json" })
                    .catch((e)=> {
                        console.log(e)
                    });
        if (data.status === 200 && !data.error) {
            commit('setBloggerTopRank', data.data.data[0]); 
            commit('setBloggerRankingData', data.data.data);
        } else {
            return Promise.reject(data);
        }
        
    },
    async getBloggerLoginData({commit}, params){
        const API_url = 'https://www.pixnet.net/mainpage/api/channel/getTopBlogger';
        const data = await axios.get(API_url , 
                         { params: params, responseType: "json" })
                         .catch((e)=> {
                            console.log(e)
                         });
        if (data.status === 200 && !data.error) {
            commit('setBloggerLoginFollowModelId', data.data.model_id);
            commit('setBloggerLoginData', data.data.data);
        } else {
            return Promise.reject(data);
        }
    }
}