
import { mapMutations } from 'vuex';
import HeaderTemplate from '~/components/common/Header.vue';
import HeaderTemplateV3 from '~/components/v3/HeaderV3.vue';
import IndexTopFalcon from '~/components/common/IndexTopFalcon.vue';
import FooterTemplate from '~/components/common/Footer.vue';
import PrivacyTabContainer from '~/components/common/tab/PrivacyTabContainer.vue';
import AppDirect from '~/components/common/AppDirect.vue';
import AppDirectQR from '~/components/common/AppDirectQR.vue';
import PostButton from '../components/v3/utils/PostButton.vue';

export default {
    name: "default-page",
    components: {
            HeaderTemplate,
            HeaderTemplateV3,
            IndexTopFalcon,
            FooterTemplate,
            PostButton,
            PrivacyTabContainer,
            AppDirect,
            AppDirectQR
    },
    data() {
        return {
            viewInnerWidth: false
        }
    },
    computed: {
        router_name() {
            return this.$route.name;
        },
        dark_mode() {
            if(this.router_name === "Channel-Movie") {
                return "black_template"
            } else {
                return ""
            }
        }
    },
    methods: {
        ...mapMutations([
            'updateSafariInnerHeight'
        ]),
        checkViewInnerWidth() {
            this.viewInnerWidth = window.innerWidth > 970;
        }
    },
    mounted() {
        this.checkViewInnerWidth();
        this.updateSafariInnerHeight();
    },
}
