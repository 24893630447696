
import { TITLE } from '~/utils/const';

export default {
    name: "seo-container",
    data() {
        return {
            title: TITLE,
        }
    },
}
