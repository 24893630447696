
export default {
    name: "panel-card",
    props: {
        notification: {
            type: Object
        }
    },
    data() {
		return {
            localIsRead: this.notification.is_read,
		}
	},
    computed: {
    },
    methods: {
        toggleIsRead(item) {
            if (!this.localIsRead) {
                this.localIsRead = true;
            }
            this.$emit('readMessage', item);
        }
    }
}
