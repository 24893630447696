import axios from "axios";

export const state = () => ({
    tag_data: [],
    all_site_tag_data: [],
    index_tag_data: [],
    all_site_model_id: "",
    searchtext_model_id: ""
})
export const mutations = {
    setTagData(state, tag_data) {
        state.tag_data = tag_data;
    },
    setAllSiteTagData(state, tag_data) {
        state.all_site_tag_data = tag_data;
    },
    setIndexTagData(state, tag_data) {
        state.index_tag_data = tag_data;
    },
    setAllSiteModelId(state, id) {
        state.all_site_model_id = id;
    },
    setSearchModelId(state, id) {
        state.searchtext_model_id = id;
    }
}
export const actions = {
    async getTagData({commit}, params){
        const API_url = 'https://topic.events.pixnet.net/api/getAllCurations';
        const data = await axios.get(API_url , 
                         { params: params, responseType: "json" })
                         .catch((e)=> {
                            console.log(e)
                         });
        if (data.status === 200 && !data.error) {
            commit('setTagData', data.data.data.tags);
        } else {
            return Promise.reject(data);
        }
        
    },
    async getAllSiteTagData({commit}){
        const API_url = 'https://topic.events.pixnet.net/api/getAllCurations';
        const data = await axios.get(API_url , 
                         { responseType: "json" })
                         .catch((e)=> {
                            console.log(e)
                         });
        if (data.status === 200 && !data.error) {
            commit('setAllSiteModelId', data.data.model_id);
            commit('setAllSiteTagData', data.data.data.tags);
        } else {
            return Promise.reject(data);
        }
        
    },
    async getIndexTagData({commit}){
        const API_url = 'https://www.pixnet.net/mainpage/api/channel/getGroupHotTags';
        const params = { group_id: 0, size: 10 }
        const data = await axios.get(API_url , 
                         { params: params, responseType: "json" })
                         .catch((e)=> {
                            console.log(e)
                         });
        const random_20 = data.data.data.tags.sort(() => Math.random() - 0.5).slice(0, 20)
        if (data.status === 200 && !data.error) {
            commit('setSearchModelId', data.data.model_id);
            commit('setIndexTagData', random_20);
        } else {
            return Promise.reject(data);
        }
    },

}