import axios from "axios";
export const state = () => ({
    banner_data: []
})
export const mutations = {
    setBannerData(state, banner_data) {
        state.banner_data = banner_data;
    },
    cleanBannerData(state) {
        state.banner_data = [];
    }
}
export const actions = {
    async getBannerData({commit}, params){
        const API_url = 'https://www.pixnet.net/mainpage/api/channel/getBanners';
        const data = await axios.get(API_url , 
                         { params: params, responseType: "json" })
                         .catch((e)=> {
                            console.log(e)
                         });
        if (data.status === 200 && !data.error) {
            commit('setBannerData', data.data.data);
        } else {
            commit('cleanBannerData');
            return Promise.reject(data);
        }
    },
}