// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../../static/img/public/v3/icon-post-pen-dark.svg");
var ___CSS_LOADER_URL_IMPORT_1___ = require("../../../static/img/public/slide-s-left.svg");
var ___CSS_LOADER_URL_IMPORT_2___ = require("../../../static/img/public/v3/icon-post-pen-white.svg");
var ___CSS_LOADER_URL_IMPORT_3___ = require("../../../static/img/public/v3/icon-post-typing.svg");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
var ___CSS_LOADER_URL_REPLACEMENT_3___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_3___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".postbutton.used[data-v-09d9e4d5]{z-index:61}.postbutton__btn[data-v-09d9e4d5]{position:fixed;right:16px;z-index:50;padding:11px;background-color:#fff;border:#EDEDED 1px solid;border-radius:50%;box-shadow:0 0 20px 0 rgba(10,10,10,0.1);transition:.3s}.postbutton__btn i[data-v-09d9e4d5]{display:block;width:24px;height:24px;background-size:cover;background-position:center}.postbutton__btn.create-post[data-v-09d9e4d5]{bottom:40px}.postbutton__btn.create-post.selected[data-v-09d9e4d5]{border:#ffd3b0 1px solid;background-color:#fff4e8}.postbutton__btn.create-post i[data-v-09d9e4d5]{background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ")}.postbutton__btn.scroll-top[data-v-09d9e4d5]{bottom:100px}.postbutton__btn.scroll-top i[data-v-09d9e4d5]{background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ");transform:rotate(90deg)}.postbutton__panel[data-v-09d9e4d5]{position:fixed;bottom:40px;right:80px;z-index:50;padding:16px 0;border-radius:10px;background-color:#fff;box-shadow:0 8px 20px rgba(117,117,117,0.1)}.postbutton__panel-option[data-v-09d9e4d5]{display:flex;justify-content:center;align-items:center;padding:8px 16px}.postbutton__panel-option i[data-v-09d9e4d5]{display:block;width:20px;height:20px;margin-right:4px;background-size:cover;background-position:center}.postbutton__panel-option.create-article i[data-v-09d9e4d5]{background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_2___ + ")}.postbutton__panel-option.create-short-post i[data-v-09d9e4d5]{background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_3___ + ")}.postbutton__panel-option p[data-v-09d9e4d5]{font-size:14px;line-height:20px;font-weight:500;color:#424242}\n", ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___;
