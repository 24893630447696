import axios from "axios";
export const state = () => ({
    couponToken: '',
    couponData: []
})
export const mutations = {
    setCouponToken(state, token) {
        state.couponToken = token;
    },
    setCouponData(state, data){
        state.couponData = data;
    }
}
export const actions = {
    async getCouponToken({ commit, state }) {
        try {
            const formData = new FormData();
            formData.append('uuid', '5f876fb38e56b')

            const { data } = await axios.post(`https://api-lightning-rod.pixnet.cc/token`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    'authorization': 'Bearer 0,0'
                }
            });

            commit('setCouponToken', data.data.token);
        } catch (error) {
            console.error(error);
        }
    },
    async getCouponData({ commit, state }) {
        try {
            const { data } = await axios.get(`https://api-lightning-rod.pixnet.cc/public/coupon`, {
                params: {
                    category: 6
                },
                headers: {
                    'authorization': `Bearer ${state.couponToken}`
                }
            });

            commit('setCouponData', data.data.public);
        } catch (error) {
            console.error(error);
        }
    }
}