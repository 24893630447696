
export default {
    name: 'footer-component',
    data() {
        return {
            year_data: '',
            policy_check: true
        }
    },
    methods: {
        getNowYear() {
            let date_data = new Date();
            this.year_data = date_data.getFullYear()
        }
    },
    async fetch() {
        this.getNowYear();
    }
}
