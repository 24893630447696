
import { mapActions, mapMutations } from 'vuex';
import ToastContainer from '../lightbox/ToastContainer.vue';
import axios from "axios";
import UrlOgReview from '../article/sub/UrlOgReview.vue';
export default {
    name: 'post-and-edit-short-article-module',
    components: {
        ToastContainer,
        UrlOgReview
    },
    props: {
        page: String,
        page_uniqid: String
    },
    data() {
        return {
            leave_alert: false,
            not_input: true,
            img_uploading: false,
            total_uploading: false,
            post_content: '',
            img_upload: [],
            review_img_upload: [],
            base64_storage: '',
            accept_img_type: 'image/png, image/jpeg, image/gif, image/heic, image/svg',
            accept_img_size: 6291456,
            api_url_arr: {
                'create': 'https://www.pixnet.net/mainpage/api/qna/createPersonalArticle',
                'edit': 'https://www.pixnet.net/mainpage/api/qna/updatePersonalArticle',
                'delete': 'https://www.pixnet.net/mainpage/api/qna/PersonalArticle'
            },
            url_preview_data: [],
            url_preview: '',
            change_content: false,
        }
    },
    computed: {
        is_login() {
            return this.$store.state.login.login_status;
        },
        member_data() {
            return this.$store.state.login.member_data;
        },
        member_thumb() {
            return this.$store.state.login.member_data.member_thumb;
        },
        member_token() {
            return this.$store.state.login.pix_member_token.member_token;
        },
        post_mode() {
            return this.$store.state.short_article.post_mode;
        },
        edit_content() {
            return this.$store.state.short_article.edit_content;
        }
    },
    methods: {
        ...mapActions('mix_article', [
            'getMixArticleData',
        ]),
        ...mapMutations('short_article', [
            'toggleOpenPostBlock'
        ]),
        ...mapMutations('lightbox', [
            'setViewData',
        ]),
        ...mapActions('pcard', [
            'getUserShortArticle',
        ]),
        ...mapActions("pa", [
            "paEvent",
        ]),
        handleInput($event) {
            const content = $event.target.innerText;
            this.post_content = content.trim();
            this.not_input = false;
            this.detectUrlToReview(content);
        },
        closeAlert() {
            this.leave_alert = false;
        },
        checkLeave() {
            if(this.post_content == '' && this.img_upload.length == 0) {
                this.toggleOpenPostBlock();
            } else {
                this.leave_alert = true;
            }
        },
        closeAndClearSortArticle() {
            this.toggleOpenPostBlock();
            this.leave_alert = false;
            this.post_content = '';
            this.img_upload = [];
            this.review_img_upload = [];
        },
        resetArticleData(){
            this.img_upload = [];
            this.review_img_upload = [];
            this.post_content = '';
            this.total_uploading = false;
            this.img_uploading = false;
        },
        setArticleData(article){
            this.img_upload = article.images;
            this.review_img_upload = article.images;
            this.post_content = article.content;
            this.url_preview = article.preview_url;
        },
        openToast(){
            this.$refs.toast_box.openToastElement();
        },
        openToastAfterPost() {
            this.$parent.openToast();
        },
        cleanPreviewUrl() {
            this.url_preview = '';
        },
        async postShortArticle(){
            this.paClickSendFunction()
            const API_url = this.api_url_arr[this.post_mode];
            const params = {
                page_size: 20,
                member_token: this.member_token,
                cursor: 0,
                refesh: 1
            };
            const img_final_upload = this.img_upload;
            this.img_uploading = true;
            this.total_uploading = true;
            const image_to_base64 = await Promise.all(img_final_upload.map((item) => typeof item === 'string' ? item : this.toBase64(item))); // 因為可能是URL string或file type
            
            let bodyFormData = new FormData();
            bodyFormData.append('hash_id', this.edit_content.id ? this.edit_content.id : '')
            bodyFormData.append('member_token', this.member_token)
            bodyFormData.append('source', 'www')
            bodyFormData.append('content', this.post_content)
            bodyFormData.append('pictures', JSON.stringify(image_to_base64))
            bodyFormData.append('preview_url', this.url_preview)
        
            const req = await axios({
                method: 'post',
                url: API_url,
                data: bodyFormData,
                headers: { "Content-Type": "multipart/form-data" },
            }).catch((e)=> {
                console.log(e)
            })

            console.log(req);

            if (req.status === 200 && !req.error) {
                if(this.post_mode == 'create') {
                    this.setViewData("create_correct");
                    this.openToastAfterPost();

                    if (this.$route.name !== 'v3mobile') {
                        const index_main_tab = document.getElementById("index_main_tab");
                        const instance = index_main_tab.getBoundingClientRect().top 
                        window.scrollTo({
                            top: instance - 100
                        });
                    }
                } else if (this.post_mode == 'edit') {
                    this.setViewData("edit_correct");
                    this.openToastAfterPost();
                }
                if(this.page != 'pcard') {
                    if (this.$route.name !== 'v3mobile') {
                        this.$parent.$parent.$refs.index_tab.activeTab(1);
                    }
                    this.resetArticleData();
                    await this.getMixArticleData(params);  
                } else {
                    const short_article_param = {
                        member_uniqid: this.page_uniqid,
                        member_token: this.member_token,
                    }
                    await this.getUserShortArticle(short_article_param)
                }
                
                this.toggleOpenPostBlock();
                // PA 
                const payload = {
                    device: 'mobile',
                    action: 'click::發布::post_card::user_action',
                    label: '短貼文::發布'
                }
                this.paEvent(payload);
            } else if(req.status === 401) {
                alert('目前登入已過期，須重新整理');
            } else {
                return Promise.reject(req);
            }
        },
        clickUploadImage() {
            this.$refs.img_upload.click();
        },
        async handleUploadImage() {
            const files = this.$refs.img_upload.files;
            const arr = [...this.img_upload];
            if (files === null || files.length == 0 || files[0] === undefined) return;
            
            for (var i = 0; i < files.length; i++) {
                if(files[i].size > this.accept_img_size) {
                    this.setViewData("most_6");
                    this.openToast();
                } else if(files[i].type == 'image/heic') {
                    this.img_uploading = true;
                    const toPng = await this.convertHeicToPng(files[i]);
                    try {
                        if (toPng && toPng.size <= this.accept_img_size) {
                            arr.push(toPng);
                            this.img_uploading = false;
                        } else if(toPng.size > this.accept_img_size) {
                            this.img_uploading = false;
                            this.setViewData("most_6");
                            this.openToast();
                        } else {
                            this.img_uploading = false;
                            errorProvider('heic 檔案上傳失敗！');
                        }
                    } catch (err) {
                        errorProvider(err);
                    }
                    
                } else {
                    arr.push(files[i])
                }
            }
            if (arr.length > 10) {
                this.setViewData("most_10");
                this.openToast();
            } 
            this.img_upload = arr.slice(0, 10);
            this.review_img_upload = this.img_upload.map((e)=> typeof e === 'string' ? e : this.changeFileToImgUrl(e));
        },
        changeFileToImgUrl(file) {
            return URL.createObjectURL(file)
        },
        toBase64(file) {
            return new Promise((resolve, reject) => {
                const reader = new FileReader();
                reader.readAsDataURL(file);
                reader.onload = () => resolve(reader.result);
                reader.onerror = (error) => reject(error);
            });
        },
        deleteUploadedImage(idx) {
            this.img_upload = this.img_upload.slice(0, idx).concat(this.img_upload.slice(idx+1));
            this.review_img_upload = this.img_upload.map((e)=> typeof e === 'string' ? e : this.changeFileToImgUrl(e))
        },
        async convertHeicToPng(file) {
            const heic2any = (await import('heic2any')).default;
            return new Promise((resolve, reject) => {
                heic2any({
                    blob: file,
                    toType: 'image/jpeg',
                    quality: .5,
                }).then(async (result) => {
                    let newFile = new File(
                        [result],
                        file.name.toLowerCase().replace(/.heic$/, '.jpeg'), {
                            type: 'image/jpeg',
                            lastModified: new Date().getTime(),
                        });
                    resolve(newFile);
                }).catch((error) => {
                    reject(error);
                });
            });
        },
        paClickSendFunction() {
            window._piq.push([
                'trackEvent',
                `rs2022::十大頻道::首頁`,
                `click::發布::post_card::user_action`,
                `短貼文::發布`,
                ``
            ]);
        },
        async pastingToDeleteTextStyleAndUploadImage(e) {
            e.preventDefault();
            const clp = (e.originalEvent || e).clipboardData || window.clipboardData;
            if (clp === undefined || clp === null) return;
            const text = clp.getData('text/plain') || '';
            if (text !== '') {
                document.execCommand('insertText', false, text);
            }

            if (clp.files?.length) {
                const files = e.clipboardData.files;
                // 有return要早點return
                if (files === null || files.length == 0 || files[0] === undefined) return;
                const arr = [...this.img_upload];

                for (var i = 0; i < files.length; i++) {
                    
                    if (files[i].size > this.accept_img_size) {
                        this.setViewData('most_6');
                        this.openToast();
                    } else if (files[i].type == 'image/heic') {
                        try {
                            // 不確定會不會掛掉的東西要放進try catch
                            const toPng = await this.convertHeicToPng(files[i]);
                            // 確定不會掛掉的話不用包進去
                            this.img_uploading = true;
                            if (toPng && toPng.size <= this.accept_img_size) {
                                arr.push(toPng);
                                this.img_uploading = false;
                            } else if(toPng.size > this.accept_img_size) {
                                this.img_uploading = false;
                                this.setViewData("most_6");
                                this.openToast();
                            } else {
                                this.img_uploading = false;
                                errorProvider('heic 檔案上傳失敗！');
                            }
                        } catch (err) {
                            errorProvider(err);
                        }
                    } else {
                        arr.push(files[i]);
                    }
                }
                if (arr.length > 10) {
                    this.setViewData("most_10");
                    this.openToast();
                } 
                this.img_upload = arr.slice(0, 10);

                this.review_img_upload = this.img_upload.map((e)=> typeof e === 'string' ? e : this.changeFileToImgUrl(e));
            }

            const content = e.target.innerText;

            setTimeout(() => {
                this.$refs.editableDiv.scrollTop = this.$refs.editableDiv.scrollHeight;
            }, 0);
        },
        async dropImageAndUpload(e) {
            e.preventDefault();
            // console.log(e)
        },
        async detectUrlToReview(input_string) {
            const urlRegex = /(https?:\/\/[^\s]+)/g;
            const match = input_string.match(urlRegex);
            if (match) {
                this.change_content = true;
                this.url_preview = match[match.length-1];
                try {
                    const API_url = 'https://www.pixnet.net/mainpage/api/qna/getOgInfo';
                    const params = {
                        member_token: this.member_token,
                        url: match[match.length-1]
                    }
                    const data = await axios.get(API_url , 
                        { params: params, responseType: "json" })
                    if (data.status === 200 && !data.error) {
                        this.url_preview_data = []
                        this.url_preview_data.push(data.data.data);
                    } else {
                        alert("登入認證過期，請重新登入")
                        return Promise.reject(data);
                    }
                    
                } catch(e) {
                    console.log(e)
                }  
            }
        },
        changeStyleWhenTextHaveHyperLink(input_string) {
            const urlRegex = /(https?:\/\/[^\s]+)/g;
            const match = input_string.replaceAll(urlRegex, '<span>$1</span>');
            return match;
        }
    },
    mounted() {
        if(this.post_mode == 'create') {
            this.resetArticleData();
        } else if (this.post_mode == 'edit') {
            this.setArticleData(this.edit_content);
        }
        document.body.classList.add('open_mask');
    },
    destroyed () {
        document.body.classList.remove('open_mask');
        this.change_content = false;
    }
}
