import axios from "axios";

export const state = () => ({
    recipe_data: []
})

export const mutations = {
    setRecipeData(state, data) {
        state.recipe_data = data;
    }
}

export const actions = {
    async getRecipeData({commit}){
        const API_url = 'https://www.pixnet.net/mainpage/api/channel/getArticles';
        const params = {id: 1, cat_id: 27, per_page: 6}
        const data = await axios.get(API_url , 
                         { params: params, responseType: "json" })
                         .catch((e)=> {
                            console.log(e)
                         });
        if (data.status === 200 && !data.error) {
            commit('setRecipeData', data.data.data.articles);
        } else {
            return Promise.reject(data);
        }
    }
}