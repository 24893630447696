export const state = () => ({
    tab_id: 1,
    tab_highlight_style: {
        left: 0,
        width: 0
    },
    window_width: 0
})

export const mutations = {
    updateTabId(state, id) {
        state.tab_id = id;
    },
    updateTabWidth(state, width){
        state.tab_highlight_style.width = width;
    },
    updateTabLeft(state, left) {
        state.tab_highlight_style.left = left;
    },
    updateWindowWidth(state, width) {
        state.window_width = width;
    }
}