
import { mapActions, mapMutations } from 'vuex';

export default {
    name: "app-direct",
    data(){
        return {

        }
    },
    computed: {
        search_active_status() {
            return this.$store.state.search.search_active;
        },
        app_direct_status() {
            return this.$store.state.lightbox.app_direct;
        },
        pcard_fans_list_status() {
            return this.$store.state.pcard.pcard_fans_list_status;
        },
        app_redirect_detail() {
            return this.$store.state.app_direct.app_redirect_detail;
        }
    },
    methods: {
        ...mapMutations('lightbox', [
            'closeAppDirect',
        ]),
        ...mapActions("app_direct", [
            'getAppRedirectDetail',
        ]),
        closeDirect() {
            this.closeAppDirect();
        },
        paFunction() {
            if (window.innerWidth < 1025 && !this.closeBlock) {
                let reg_link = this.app_redirect_detail?.button_url.replace('https://','http://');
            window._piq.push(['trackEvent', 'rs2022::十大頻道::首頁', 'imps::置頂痞客邦APP導流::anchor::user_action', '置頂痞客邦APP導流::' + reg_link, '1.0001']); 
            }
        },
        paClickFunction() {
            let reg_link = this.app_redirect_detail.button_url.replace('https://','http://');
            window._piq.push(['trackEvent', 'rs2022::十大頻道::首頁', 'click::置頂痞客邦APP導流::anchor::user_action', '置頂痞客邦APP導流::' + reg_link, '1.0001']);
        },
    },
    async fetch() {
        await this.getAppRedirectDetail(); 
    },

    async mounted(){
        await this.paFunction();
         
    },
}
