export const state = () => ({
    tab_type: "index",
    tab_id: 1,
    tab_highlight_style: {
        left: 0,
        width: 0
    },
    route_name: ""
})

export const mutations = {
    updateTabType(state, type) {
        state.tab_type = type;
    },
    updateTabId(state, id) {
        state.tab_id = id;
    },
    updateTabWidth(state, width){
        state.tab_highlight_style.width = width;
    },
    updateTabLeft(state, left) {
        state.tab_highlight_style.left = left;
    },
    updateRouteName(state, name) {
        state.route_name = name;
    }
}